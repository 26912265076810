import { FunctionComponent } from 'react';
import { Row, Col, Button } from 'antd';
import './style.scss';

interface ErrorBoxProps {
  errorCode?: number;
  errorDesp?: string;
}
const ErrorBox: FunctionComponent<ErrorBoxProps> = ({ errorCode, errorDesp }) => {
  return (
    <div className="box-404">
      <Row align="middle" justify="center">
        <Col span={24} className="error-code">
          <i className="icon-help-circled" />
          {errorCode || '404'}
        </Col>
        <Col span={24} className="error-text">
          {errorDesp ? <p>{errorDesp}</p> : <p>Ooops! We can&apos;t find what you&apos;re looking for.</p>}
          <Button className="back-btn" href="/" icon={<i className="icon-left" />}>
            Click to go back
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ErrorBox;
