import queryString from 'query-string';
import { grpc } from '@improbable-eng/grpc-web';
import { decode } from 'jsonwebtoken';
import {
  JoinRoomRes,
  Message,
  JoinRoomReq,
  MessageReq,
  ReadMessageReq,
  None,
  FileReq,
  UserStatusRes,
} from '@grpc/web/chat_pb';
import { Device, RoomType } from './enum';
import { joinRoom, messageStreaming, readMessage, userStatus, fileStreaming } from './grpc';

export interface RoomData {
  bookingID: number;
  roomType: RoomType | undefined;
  device: Device;
  token: string;
}

export const initialData = (): RoomData => {
  const search = window.location.search.replace('?', '');
  const parse = queryString.parse(search);
  const bookingID = parseInt(window.location.pathname.split('/')[2], 10);
  const decodedJWT = decode(parse.authorization as string, { complete: true }) as { [key: string]: any };
  const token = parse.authorization as string;
  let roomType;
  if (decodedJWT) {
    const { type, user_type: userType } = decodedJWT.payload;
    roomType = type;

    switch (userType) {
      case 'admin':
        roomType = RoomType.Admin;
        break;
      case 'staff':
        roomType = RoomType.Dealer;
        break;
      default:
    }
  }

  return {
    bookingID,
    roomType,
    device: parse.device === 'mobile' ? Device.Mobile : Device.Desktop,
    token,
  };
};

interface GrpcFunction {
  joinRoomClient: grpc.Client<JoinRoomReq, JoinRoomRes>;
  messageClient: grpc.Client<MessageReq, Message>;
  readMessageClient?: (token: string, bookingID: string) => grpc.Client<ReadMessageReq, None>;
  fileStreamingClient?: grpc.Client<FileReq, Message>;
  userStatusClient?: grpc.Client<None, UserStatusRes>;
}

export const initialGrpcClient = (device: Device): GrpcFunction => {
  const { bookingID, roomType, token } = initialData();

  // Default Initial
  const grpcFunction: GrpcFunction = {
    joinRoomClient: joinRoom(token, bookingID.toString()),
    messageClient: messageStreaming(token, bookingID.toString()),
  };

  switch (roomType) {
    case RoomType.Consumer: {
      grpcFunction.readMessageClient = readMessage;
      if (device === Device.Desktop) grpcFunction.fileStreamingClient = fileStreaming(token, bookingID.toString());
      if (device === Device.Desktop) grpcFunction.userStatusClient = userStatus(token, bookingID.toString());
      break;
    }
    case RoomType.Dealer: {
      grpcFunction.readMessageClient = readMessage;
      if (device === Device.Desktop) grpcFunction.fileStreamingClient = fileStreaming(token, bookingID.toString());
      if (device === Device.Desktop) grpcFunction.userStatusClient = userStatus(token, bookingID.toString());
      break;
    }
    default:
      // For RoomType.Admin
      break;
  }
  return grpcFunction;
};
export default {};
