import { grpc } from '@improbable-eng/grpc-web';
import { Message, FileReq } from '@grpc/web/chat_pb';

export const fileToArrayBinary = (file: File) =>
  new Promise<Uint8Array[]>((resolve, reject) => {
    const onBinaryLoad = (x: ProgressEvent<FileReader>) => {
      const buffer = x.target?.result;

      if (buffer) {
        const uint = new Uint8Array(buffer as ArrayBuffer);
        const chunks = [];
        const chunkSize = 400000;

        for (let i = 0; i < uint.length; i += chunkSize) {
          chunks.push(uint.slice(i, i + 400000));
        }
        resolve(chunks);
      } else {
        reject();
      }
    };
    const fileReader = new FileReader();
    fileReader.onload = onBinaryLoad;
    fileReader.readAsArrayBuffer(file);
  });

export const mapFilePromise = (
  files: { [key: number]: File } | FileList,
  callback: (rawFile: File) => any,
): Promise<void>[] =>
  Object.keys(files).map(async (k) => {
    const rawFile = files[parseInt(k, 10)];
    await callback(rawFile);
  });

export const sendFileStreaming = async (
  rawFile: Uint8Array,
  chunkTotal: number,
  chunkIndex: number,
  fileName: string,
  fileStreamingClient?: grpc.Client<FileReq, Message>,
) => {
  if (!fileStreamingClient) return Promise.reject<Error>(new Error('fileStreamingClient undifined'));
  const fileReq = new FileReq();
  fileReq.setFileName(fileName);
  fileReq.setChunkIndex(chunkIndex);
  fileReq.setChunkTotal(chunkTotal);
  fileReq.setContent(rawFile);
  fileStreamingClient.send(fileReq);
  return Promise.resolve<Uint8Array>(rawFile);
};
