import { FunctionComponent } from 'react';
import './style.scss';

interface TextBoxWithLabelProps {
  label: string;
  text: string;
}
export const TextBoxWithLabel: FunctionComponent<TextBoxWithLabelProps> = ({ label, text }) => {
  return (
    <div className="text-box">
      <p className="text-box__label">{label}</p>
      <p className="text-box__text">{text}</p>
    </div>
  );
};

export default TextBoxWithLabel;
