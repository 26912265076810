let ENV_SEARCH_END_POINT = '';
let ENV_GRPC_END_POINT = '';

if (process.env.REACT_APP_STAGE === 'production') {
  ENV_SEARCH_END_POINT = process.env.REACT_APP_SEARCH_END_POINT || 'https://search.drivehub.co';
  ENV_GRPC_END_POINT = process.env.REACT_APP_GRPC_END_POINT || 'https://chat-backend-ws.drivehub.co';
} else {
  ENV_SEARCH_END_POINT = 'https://app-dev.drivehub.co';
  ENV_GRPC_END_POINT = 'https://chat-backend-ws-dev.drivehub.co';
}

export const SEARCH_END_POINT = ENV_SEARCH_END_POINT;
export const GRPC_END_POINT = ENV_GRPC_END_POINT;

export default {};
