import { PureComponent } from 'react';
import * as dayjs from 'dayjs';
import { Routes, Route } from 'react-router';
import { getPath } from './routes';
import ChatPage from './routes/Chat';
import ChatMobilePage from './routes/ChatMobile';
import TemplatePreview from './routes/TemplatePreview';
import ErrorPage from './routes/404';
import 'dayjs/locale/th'; // use locale
import './styles/antd.less';
import './App.scss'; // import locale

dayjs.locale('th');

interface AppState {
  isMobile: boolean;
}

class App extends PureComponent<any, AppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', (e) => this.handleResponsive(e), false);
    if (window.innerWidth <= 992) this.setState({ isMobile: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', (e) => this.handleResponsive(e), false);
  }

  handleResponsive = (e: any) => {
    if (e.target.innerWidth <= 992) return this.setState({ isMobile: true });
    return this.setState({ isMobile: false });
  };

  render() {
    const { isMobile } = this.state;

    return (
      <Routes>
        <Route path="/rooms">
          <Route path=":roomID" element={<ChatPage isMobile={isMobile} />} />
          <Route path=":roomID/mobile" element={<ChatMobilePage isMobile={isMobile} />} />
        </Route>
        <Route path="/chat">
          <Route path=":roomID/template_preview" />
          <Route path=":roomID/template_preview/:templateID" element={<TemplatePreview isMobile={isMobile} />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    );
  }
}

export default App;
