import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { grpc } from '@improbable-eng/grpc-web';
import { GetPreviewReq, GetPreviewResp, Message } from '@grpc/web/chat_pb';
import { Chat } from '@grpc/web/chat_pb_service';

import type { ChatGetPreview } from '@grpc/web/chat_pb_service';

import { GRPC_END_POINT } from '@config';
import MessageBox from '@pages/chat/components/message-box';
import '@pages/chat/components/chat-messages/style.scss';
import './style.scss';

type Params = {
  roomID: string;
  templateID: string;
};
const PreviewRoom = () => {
  const location = useLocation();
  const params = useParams() as Params;
  const token = new URLSearchParams(location.search).get('authorization') as string;
  const [messages, setMessages] = useState<Array<Message.AsObject>>([]);
  useEffect(() => {
    const client = grpc.client<GetPreviewReq, GetPreviewResp, ChatGetPreview>(Chat.GetPreview, {
      host: GRPC_END_POINT,
      transport: grpc.WebsocketTransport(),
      debug: true,
    });

    client.start(
      new grpc.Metadata({
        'booking-id': params.roomID,
        authorization: token,
      }),
    );
    const req = new GetPreviewReq();
    req.setBookingId(parseInt(params.roomID, 10));
    req.setTemplateId(parseInt(params.templateID, 10));
    client.send(req);

    client.onMessage((response: GetPreviewResp) => {
      setMessages(
        response.toObject().messagesList.map((m, i) => {
          return {
            ...m,
            uid: `${i}`,
            id: i,
          };
        }),
      );
    });
  }, [params, token]);
  return (
    <div className="message-box message-box--dealer message-preview--wrapper">
      <p className="preview--detail">
        รายละเอียดด้านล่างจะเป็นข้อความที่ลูกค้าได้รับจากระบบ โปรดตรวจสอบความถูกต้องอีกครั้งก่อนกดส่งข้อความ
      </p>
      {messages.map((message) => {
        return (
          <MessageBox
            id={message.id}
            uid={message.uid}
            key={message.uid}
            type={message.type}
            text={message.text}
            createdAt="1612926011469"
            fileName=""
            fileUrl=""
            customeFieldMap={message.customeFieldMap}
            avatar=""
            senderType={2}
            showSeparateLine={false}
          />
        );
      })}
    </div>
  );
};
export default PreviewRoom;
