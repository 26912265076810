// package: protos
// file: src/protos/chat.proto

var src_protos_chat_pb = require('./chat_pb');
var grpc = require('@improbable-eng/grpc-web').grpc;

var Chat = (function () {
  function Chat() {}
  Chat.serviceName = 'protos.Chat';
  return Chat;
})();

Chat.JoinRoom = {
  methodName: 'JoinRoom',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.JoinRoomReq,
  responseType: src_protos_chat_pb.JoinRoomRes,
};

Chat.MessageStreaming = {
  methodName: 'MessageStreaming',
  service: Chat,
  requestStream: true,
  responseStream: true,
  requestType: src_protos_chat_pb.MessageReq,
  responseType: src_protos_chat_pb.Message,
};

Chat.FileStreaming = {
  methodName: 'FileStreaming',
  service: Chat,
  requestStream: true,
  responseStream: true,
  requestType: src_protos_chat_pb.FileReq,
  responseType: src_protos_chat_pb.Message,
};

Chat.GetUserStatus = {
  methodName: 'GetUserStatus',
  service: Chat,
  requestStream: false,
  responseStream: true,
  requestType: src_protos_chat_pb.None,
  responseType: src_protos_chat_pb.UserStatusRes,
};

Chat.ReadMessage = {
  methodName: 'ReadMessage',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.ReadMessageReq,
  responseType: src_protos_chat_pb.None,
};

Chat.SendUserStatus = {
  methodName: 'SendUserStatus',
  service: Chat,
  requestStream: true,
  responseStream: false,
  requestType: src_protos_chat_pb.UserStatusReq,
  responseType: src_protos_chat_pb.None,
};

Chat.Inbox = {
  methodName: 'Inbox',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.InboxReq,
  responseType: src_protos_chat_pb.InboxRes,
};

Chat.InboxStreaming = {
  methodName: 'InboxStreaming',
  service: Chat,
  requestStream: false,
  responseStream: true,
  requestType: src_protos_chat_pb.InboxReq,
  responseType: src_protos_chat_pb.InboxBooking,
};

Chat.GetInboxUnread = {
  methodName: 'GetInboxUnread',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.InboxReq,
  responseType: src_protos_chat_pb.InboxUnreadRes,
};

Chat.LoadMessages = {
  methodName: 'LoadMessages',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.LoadMessageReq,
  responseType: src_protos_chat_pb.LoadMessageResp,
};

Chat.Files = {
  methodName: 'Files',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.FileListReq,
  responseType: src_protos_chat_pb.FileListResp,
};

Chat.MyFiles = {
  methodName: 'MyFiles',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.None,
  responseType: src_protos_chat_pb.FileListResp,
};

Chat.GetTemplates = {
  methodName: 'GetTemplates',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.GetTemplatesReq,
  responseType: src_protos_chat_pb.GetTemplatesResp,
};

Chat.GetTemplate = {
  methodName: 'GetTemplate',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.GetTemplateReq,
  responseType: src_protos_chat_pb.Template,
};

Chat.CreateTemplate = {
  methodName: 'CreateTemplate',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.CreateTemplateReq,
  responseType: src_protos_chat_pb.CreateTemplateResp,
};

Chat.UpdateTemplate = {
  methodName: 'UpdateTemplate',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.UpdateTemplateReq,
  responseType: src_protos_chat_pb.UpdateTemplateResp,
};

Chat.DeleteTemplate = {
  methodName: 'DeleteTemplate',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.DeleteTemplateReq,
  responseType: src_protos_chat_pb.None,
};

Chat.SendTemplate = {
  methodName: 'SendTemplate',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.SendTemplateReq,
  responseType: src_protos_chat_pb.SendTemplateResp,
};

Chat.GetPreview = {
  methodName: 'GetPreview',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.GetPreviewReq,
  responseType: src_protos_chat_pb.GetPreviewResp,
};

Chat.AddDocument = {
  methodName: 'AddDocument',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.DocumentReq,
  responseType: src_protos_chat_pb.DocumentResp,
};

Chat.UpdateDocument = {
  methodName: 'UpdateDocument',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.DocumentReq,
  responseType: src_protos_chat_pb.DocumentResp,
};

Chat.InitialBanks = {
  methodName: 'InitialBanks',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.RawBanksReq,
  responseType: src_protos_chat_pb.BankResp,
};

Chat.AddBank = {
  methodName: 'AddBank',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.BankReq,
  responseType: src_protos_chat_pb.BankResp,
};

Chat.UpdateBank = {
  methodName: 'UpdateBank',
  service: Chat,
  requestStream: false,
  responseStream: false,
  requestType: src_protos_chat_pb.BankReq,
  responseType: src_protos_chat_pb.BankResp,
};

exports.Chat = Chat;

function ChatClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ChatClient.prototype.joinRoom = function joinRoom(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.JoinRoom, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.messageStreaming = function messageStreaming(metadata) {
  var listeners = {
    data: [],
    end: [],
    status: [],
  };
  var client = grpc.client(Chat.MessageStreaming, {
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
  });
  client.onEnd(function (status, statusMessage, trailers) {
    listeners.status.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners.end.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners = null;
  });
  client.onMessage(function (message) {
    listeners.data.forEach(function (handler) {
      handler(message);
    });
  });
  client.start(metadata);
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    write: function (requestMessage) {
      client.send(requestMessage);
      return this;
    },
    end: function () {
      client.finishSend();
    },
    cancel: function () {
      listeners = null;
      client.close();
    },
  };
};

ChatClient.prototype.fileStreaming = function fileStreaming(metadata) {
  var listeners = {
    data: [],
    end: [],
    status: [],
  };
  var client = grpc.client(Chat.FileStreaming, {
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
  });
  client.onEnd(function (status, statusMessage, trailers) {
    listeners.status.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners.end.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners = null;
  });
  client.onMessage(function (message) {
    listeners.data.forEach(function (handler) {
      handler(message);
    });
  });
  client.start(metadata);
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    write: function (requestMessage) {
      client.send(requestMessage);
      return this;
    },
    end: function () {
      client.finishSend();
    },
    cancel: function () {
      listeners = null;
      client.close();
    },
  };
};

ChatClient.prototype.getUserStatus = function getUserStatus(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: [],
  };
  var client = grpc.invoke(Chat.GetUserStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    },
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    },
  };
};

ChatClient.prototype.readMessage = function readMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.ReadMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.sendUserStatus = function sendUserStatus(metadata) {
  var listeners = {
    end: [],
    status: [],
  };
  var client = grpc.client(Chat.SendUserStatus, {
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
  });
  client.onEnd(function (status, statusMessage, trailers) {
    listeners.status.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners.end.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners = null;
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    write: function (requestMessage) {
      if (!client.started) {
        client.start(metadata);
      }
      client.send(requestMessage);
      return this;
    },
    end: function () {
      client.finishSend();
    },
    cancel: function () {
      listeners = null;
      client.close();
    },
  };
};

ChatClient.prototype.inbox = function inbox(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.Inbox, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.inboxStreaming = function inboxStreaming(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: [],
  };
  var client = grpc.invoke(Chat.InboxStreaming, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    },
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    },
  };
};

ChatClient.prototype.getInboxUnread = function getInboxUnread(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.GetInboxUnread, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.loadMessages = function loadMessages(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.LoadMessages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.files = function files(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.Files, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.myFiles = function myFiles(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.MyFiles, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.getTemplates = function getTemplates(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.GetTemplates, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.getTemplate = function getTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.GetTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.createTemplate = function createTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.CreateTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.updateTemplate = function updateTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.UpdateTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.deleteTemplate = function deleteTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.DeleteTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.sendTemplate = function sendTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.SendTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.getPreview = function getPreview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.GetPreview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.addDocument = function addDocument(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.AddDocument, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.updateDocument = function updateDocument(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.UpdateDocument, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.initialBanks = function initialBanks(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.InitialBanks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.addBank = function addBank(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.AddBank, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

ChatClient.prototype.updateBank = function updateBank(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Chat.UpdateBank, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    },
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    },
  };
};

exports.ChatClient = ChatClient;
