import { FunctionComponent } from 'react';
import { Layout } from 'antd';
import Context from '@shares/context';
import PreviewRoom from '@pages/preview';
import SEO from '@shares/seo';
import { Device } from '@shares/context/enum';

interface TemplatePreviewProps {
  isMobile: boolean;
}

const TemplatePreview: FunctionComponent<TemplatePreviewProps> = ({ isMobile }) => {
  return (
    <Context device={Device.Mobile}>
      <SEO />
      <Layout className="chat">
        <PreviewRoom />
      </Layout>
    </Context>
  );
};

export default TemplatePreview;
