import { FunctionComponent } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Booking } from '@grpc/web/chat_pb';
import { TextBoxWithLabel } from '@shares/text-box';
import ExpIcon from '@assets/images/file-expired.png';
import './style.scss';

dayjs.extend(utc);
dayjs.extend(timezone);
interface BookingBoxProps {
  booking?: Booking.AsObject;
}
const TimeFormat = 'HH:mmน.';
const DateFormat = 'DD MMM YY';
const TZ = 'Asia/Bangkok';
const BookingBox: FunctionComponent<BookingBoxProps> = ({ booking }) => {
  if (!booking) return <div />;
  const { id, car, location, pickupAt, returnAt } = booking;
  const carTitle = `${car?.brand} ${car?.model} ${car?.year}`;
  const pickupDateTime = dayjs(parseInt(pickupAt, 10));
  const returnDateTime = dayjs(parseInt(returnAt, 10));

  return (
    <div className="booking-box">
      <div className="title">
        <p>หมายเลขการจอง</p>
        <p>{id}</p>
      </div>
      <div className="detail">
        <div className="detail__item">
          <img src={car?.imageUrl || ExpIcon} alt={carTitle} className="car-img" />
        </div>
        <div className="detail__item detail__item--car">
          <div className="car-title">{`${car?.brand} ${car?.model} ${car?.year}`} </div>
          <div className="location">
            <TextBoxWithLabel label="สถานที่รับรถ/คืนรถ" text={location} />
          </div>
          <div className="time-box">
            <TextBoxWithLabel label="วันที่รับรถ" text={pickupDateTime.tz(TZ).format(DateFormat)} />
            <TextBoxWithLabel label="เวลา" text={pickupDateTime.tz(TZ).format(TimeFormat)} />
          </div>
          <div className="time-box">
            <TextBoxWithLabel label="วันที่คืนรถ" text={returnDateTime.tz(TZ).format(DateFormat)} />
            <TextBoxWithLabel label="เวลา" text={returnDateTime.tz(TZ).format(TimeFormat)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingBox;
