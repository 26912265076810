import { MessageType, SenderMessageType, UserType } from '@shares/context/enum';
import { Avatar, Image, Tooltip } from 'antd';
import { UserOutlined, FileFilled } from '@ant-design/icons';
import { Message, MessageCustomField } from '@grpc/web/chat_pb';
import LoadingIcon from '@assets/shares/loading.svg';
import CloneIcon from '@assets/icons/clone.svg';
import ExpIcon from '@assets/images/file-expired.png';
import dayjs from 'dayjs';

import './style.scss';

interface MessageProps extends Message.AsObject {
  avatar: string;
  senderType: number;
  showSeparateLine: boolean;
  customeFieldMap: Array<[string, MessageCustomField.AsObject]>;
}
interface RenderMessageProps {
  type: string;
  text: string;
  fileName: string;
  fileUrl: string;
  customeFieldMap: Array<[string, MessageCustomField.AsObject]>;
}

/* eslint-disable react/no-danger */
const renderMessage = ({ type, text, fileName, fileUrl, customeFieldMap }: RenderMessageProps) => {
  switch (type) {
    case MessageType.Html: {
      return (
        <div className="message message--html">
          <div dangerouslySetInnerHTML={{ __html: text }} />
          {customeFieldMap.length > 0 && (
            <Tooltip title="คัดลอกเลขที่บัญชีแล้ว" trigger="click" color="blue">
              <div
                aria-hidden
                className="message--copy"
                role="button"
                onClick={() => {
                  const obj = {
                    copy: customeFieldMap[0][1].text,
                  };
                  window.parent.postMessage(obj, '*');
                  navigator.clipboard.writeText(customeFieldMap[0][1].text);
                }}
              >
                <img src={CloneIcon} alt="copy" /> <div>คัดลอกเลขที่บัญชี</div>
              </div>
            </Tooltip>
          )}
        </div>
      );
    }
    case MessageType.Text: {
      return <div className="message message--text">{text}</div>;
    }
    case MessageType.Image: {
      return (
        <div className="message message--img">
          <Image
            src={fileUrl}
            placeholder={<img src={LoadingIcon} className="img-loading" alt="loading" />}
            fallback={ExpIcon}
          />
        </div>
      );
    }
    case MessageType.Expired: {
      return (
        <div className="message message--img">
          <img src={ExpIcon} style={{ width: 100, padding: 20 }} alt="expired" />
        </div>
      );
    }
    case MessageType.File: {
      return (
        <a href={fileUrl} className="message message--file">
          <FileFilled width={25} height={25} className="file-icon" />
          {fileName}
        </a>
      );
    }
    default:
      return <div />;
  }
};
const renderAvatar = (senderType: number, avatar: string) => {
  switch (senderType) {
    case SenderMessageType.Dealer: {
      return <Avatar src={avatar} />;
    }
    case SenderMessageType.Consumer: {
      return <Avatar icon={<UserOutlined />} />;
    }
    default: {
      return <Avatar icon={<UserOutlined />} />;
    }
  }
};
const getSenderTypeString = (senderType: number) => {
  switch (senderType) {
    case SenderMessageType.Dealer: {
      return UserType.Dealer;
    }
    case SenderMessageType.Consumer: {
      return UserType.Consumer;
    }
    default: {
      return 'hide';
    }
  }
};
const SeparateLine: React.FunctionComponent = () => {
  return (
    <div className="message-group__item message-group__item--separate">
      <span className="separate-text">ข้อความที่ยังไม่ได้อ่าน</span>
    </div>
  );
};

const MessageBox: React.FunctionComponent<MessageProps> = ({
  avatar,
  senderType,
  createdAt,
  type,
  text,
  fileName,
  fileUrl,
  uid,
  showSeparateLine,
  customeFieldMap,
}) => {
  const unixDateTime = parseInt(createdAt, 10);
  const messageTime = dayjs(unixDateTime).format('HH:mm');

  // TODO:: if (senderType === '') return null;
  return (
    <>
      {showSeparateLine && <SeparateLine />}
      <div
        className={`message-group__item message-group__item--${getSenderTypeString(senderType)}`}
        id={`message-${uid}`}
      >
        <div className="avatar">{renderAvatar(senderType, avatar)}</div>
        {renderMessage({ type, text, fileName, fileUrl, customeFieldMap })}
        <div className="message--time">{messageTime}</div>
      </div>
    </>
  );
};

export default MessageBox;
