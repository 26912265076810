import { FunctionComponent } from 'react';
import ErrorBox from '@shares/404';
import { Helmet } from 'react-helmet';

const ErrorPage: FunctionComponent = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'th',
        }}
        title="404"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0',
          },
          {
            property: 'og:image',
            content: 'https://d1p3p3st0uh13e.cloudfront.net/favicon/drivehub.jpg',
          },
        ]}
      >
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/favicon-16x16.png"
        />
      </Helmet>
      <ErrorBox />
    </>
  );
};

export default ErrorPage;
