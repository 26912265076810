import { PureComponent, createContext } from 'react';
import queryString from 'query-string';
import {
  JoinRoomRes,
  Message,
  JoinRoomReq,
  MessageReq,
  ReadMessageReq,
  None,
  FileReq,
  UserStatusRes,
} from '@grpc/web/chat_pb';
import { grpc } from '@improbable-eng/grpc-web';
import ErrorBox from '@shares/404';
import { Device, RoomType, ChatRenderState, ConnectionStatus } from './enum';
import { initialData, initialGrpcClient, RoomData } from './permissions';

export interface ContextState {
  fetching: boolean;
  room: JoinRoomRes.AsObject;
  messages: Message.AsObject[];
  grpcFunction: {
    messageClient?: grpc.Client<MessageReq, Message>;
    readMessageClient?: (token: string, bookingID: string) => grpc.Client<ReadMessageReq, None>;
    fileStreamingClient?: grpc.Client<FileReq, Message>;
  };
  opponentIsActive: boolean;
  lastOpponentActiveAt: string;
  device: Device;
  chatRenderState: ChatRenderState;
  roomStatus: ConnectionStatus | undefined;
  retryCount: number;
  roomType?: RoomType;
  lastMessageObj?: Message.AsObject;
  getRoomData?: () => RoomData;
  isAllowToChat: boolean;
}
interface ContextProps {
  device: Device;
}

const defaultContext: ContextState = {
  fetching: true,
  room: {
    messagesList: [],
    isBookingActive: false,
    booking: undefined,
    lastMessageUid: '',
    consumer: undefined,
    dealer: undefined,
  },
  messages: [],
  grpcFunction: {},
  opponentIsActive: false,
  lastOpponentActiveAt: '',
  device: Device.Desktop,
  chatRenderState: ChatRenderState.Mobile,
  retryCount: 0,
  roomType: undefined,
  roomStatus: undefined,
  lastMessageObj: undefined,
  getRoomData: undefined,
  isAllowToChat: true,
};

export const MyContext = createContext<ContextState>(defaultContext);
class Context extends PureComponent<ContextProps, ContextState> {
  constructor(props: ContextProps) {
    super(props);
    this.state = {
      ...defaultContext,
    };
  }

  componentDidMount() {
    const { bookingID, roomType, token } = initialData();
    if (!bookingID || !roomType || !token) {
      this.setFetchingToFalse();
      return;
    }
    this.initialConnectChatRoom(bookingID, roomType);
  }

  initialConnectChatRoom = async (bookingID: number, roomType: RoomType | undefined) => {
    const { device } = this.props;
    const { joinRoomClient, messageClient, readMessageClient, fileStreamingClient, userStatusClient } =
      initialGrpcClient(device);
    const req = new JoinRoomReq();
    req.setBookingId(bookingID);

    await Promise.all([
      joinRoomClient.send(req),
      userStatusClient && userStatusClient.send(new None()),
      this.connectMessageClient(messageClient),
      this.connectUserStatusClient(userStatusClient),
    ]);

    joinRoomClient.onEnd((status: number, message: string) => {
      console.log(status, message);
      if (status === 16) {
        this.setState({ isAllowToChat: false });
      }
    });

    messageClient.onMessage((m) => {
      const { messages } = this.state;

      const incomingMessage: Message.AsObject = {
        ...m.toObject(),
      };
      const newMessage = messages.concat([incomingMessage]);
      this.setState({ messages: newMessage, lastMessageObj: incomingMessage });
    });

    joinRoomClient.onMessage((message) => {
      const data = message.toObject();
      this.setState(
        {
          room: data,
          messages: data.messagesList,
          roomType,
          device,
          chatRenderState: this.getRenderState(),
          grpcFunction: {
            messageClient,
            fileStreamingClient,
            readMessageClient,
          },
          roomStatus: ConnectionStatus.Online,
        },
        () => {
          joinRoomClient.close();
          this.setFetchingToFalse();
        },
      );
    });
  };

  fibonacci = (num: number): number => {
    let a = 1;
    let b = 0;
    let temp;
    let count = num;
    while (count >= 0) {
      temp = a;
      a += b;
      b = temp;
      count -= 1;
    }

    return b;
  };

  connectMessageClient = (messageClient: grpc.Client<MessageReq, Message>): Promise<void> => {
    messageClient.onEnd(() => {
      this.setState({ roomStatus: ConnectionStatus.Offline, fetching: true }, () => {
        const { retryCount, isAllowToChat } = this.state;
        if (isAllowToChat === true) {
          setTimeout(() => {
            const { bookingID, roomType } = initialData();
            this.initialConnectChatRoom(bookingID, roomType);
            this.setState({
              retryCount: retryCount + 1,
            });
          }, Math.min(this.fibonacci(retryCount) * 100, 300000));
        }
      });
    });
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve, reject) => resolve());
  };

  connectUserStatusClient = (userStatusClient?: grpc.Client<None, UserStatusRes>) => {
    if (!userStatusClient) return;
    userStatusClient.onMessage((status) => {
      this.setState({
        opponentIsActive: status.getIsOpponentActive(),
        lastOpponentActiveAt: status.getLatestActiveAt(),
      });
    });
  };

  getRenderState = () => {
    const search = window.location.search.replace('?', '');
    const parse = queryString.parse(search);
    return (parse.render as string) === ChatRenderState.Mobile ? ChatRenderState.Mobile : ChatRenderState.Desktop;
  };

  setFetchingToFalse = () => this.setState({ fetching: false });

  render() {
    const { children } = this.props;
    const { isAllowToChat } = this.state;
    if (isAllowToChat === false) {
      return <ErrorBox errorCode={401} errorDesp="Unauthorized Error." />;
    }

    return (
      <MyContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          ...this.state,
          getRoomData: initialData,
        }}
      >
        {children}
      </MyContext.Provider>
    );
  }
}

export default Context;
