import { FunctionComponent } from 'react';
import { Row, Col } from 'antd';
import loadingSVG from '../../../assets/shares/loading.svg';
import './style.scss';

const LoadingBox: FunctionComponent = () => {
  return (
    <div className="loading">
      <Row align="middle" justify="center">
        <Col span={24} className="loading-img">
          <img src={loadingSVG} alt="loading" />
        </Col>
      </Row>
    </div>
  );
};

export default LoadingBox;
