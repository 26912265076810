import { PureComponent, ContextType, createRef } from 'react';
import ChatHeader from '@pages/chat/components/chat-header';
import ChatFooter from '@pages/chat/components/chat-footer';
import ChatMessagesBox from '@pages/chat/components/chat-messages';
import LoadingBox from '@shares/loading';
import ErrorBox from '@shares/404';
import { MyContext } from '@shares/context';
import { Device, RoomType } from '@shares/context/enum';
import { Modal } from 'antd';
import ModalCloseIcon from '@assets/images/modal-close-icon.png';
import './style.scss';

interface ChatRoomProps {
  isMobile: boolean;
}
interface ErrorStageProps extends ErrorProps {
  visible: boolean;
}
interface ChatRoomState {
  errorStage: ErrorStageProps;
}
class ChatRoom extends PureComponent<ChatRoomProps, ChatRoomState> {
  static contextType = MyContext;

  context!: ContextType<typeof MyContext>;

  chatHeaderRef = createRef<HTMLDivElement>();

  constructor(props: ChatRoomProps) {
    super(props);
    this.state = {
      errorStage: {
        visible: false,
      },
    };
  }

  toggleError = (data: ErrorProps) => {
    const { errorMessage, errorMessageTitle } = data;
    this.setState({
      errorStage: {
        visible: true,
        errorMessage,
        errorMessageTitle,
      },
    });
  };

  render() {
    const { isMobile } = this.props;
    const { roomType, fetching, device, opponentIsActive, room } = this.context;
    const { errorStage } = this.state;
    if (!roomType && !fetching) {
      return <ErrorBox />;
    }

    return fetching ? (
      <LoadingBox />
    ) : (
      <>
        <Modal
          className="chat-alert-box"
          visible={errorStage.visible}
          onCancel={() =>
            this.setState({
              errorStage: {
                visible: false,
                errorMessage: undefined,
                errorMessageTitle: undefined,
              },
            })
          }
          closeIcon={<img src={ModalCloseIcon} className="chat-alert-close" alt="close" />}
          footer={null}
          zIndex={100}
          centered
        >
          <p>{errorStage.errorMessageTitle}</p>
          <p>{errorStage.errorMessage}</p>
        </Modal>
        {device === Device.Mobile ? (
          <div
            className={`chat-messages chat-messages--${roomType} ${
              device === Device.Mobile ? 'chat-messages--mobile' : ''
            } ${opponentIsActive ? 'online' : 'offline'} `}
            id="chat-message-ref"
          >
            <ChatMessagesBox />
          </div>
        ) : (
          <>
            <div className="chat-header" ref={this.chatHeaderRef}>
              <ChatHeader />
            </div>
            <div
              className={`chat-messages chat-messages--${roomType} ${opponentIsActive ? 'online' : 'offline'} ${
                !room.isBookingActive ? 'chat-messages--input-disable' : ''
              }`}
              id="chat-message-ref"
            >
              <ChatMessagesBox toggleError={this.toggleError} />
            </div>
            <div className="chat-footer">
              {room.isBookingActive && roomType !== RoomType.Admin && (
                <ChatFooter isMobile={isMobile} toggleError={this.toggleError} />
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

export default ChatRoom;
