export enum UserType {
  Consumer = 'consumer',
  Dealer = 'dealer',
  Admin = 'admin',
}

export enum SenderMessageType {
  Dealer = 1,
  Consumer = 2,
  Admin = 3,
  Unknown = 999,
}

export enum RoomType {
  Consumer = 'consumer',
  Dealer = 'dealer',
  Admin = 'admin',
}
export enum Device {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export enum MessageType {
  Text = 'text',
  File = 'file',
  Image = 'image',
  Html = 'html',
  Expired = 'expired',
}

export enum ChatRenderState {
  Mobile = '0',
  Desktop = '1',
}

export enum ConnectionStatus {
  Offline,
  Online,
}
