import { PureComponent, ContextType, createRef } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { MyContext } from '@shares/context';
import MessageBox from '@pages/chat/components/message-box';
import BookingBox from '@pages/chat/components/booking-box';
import { Message, ReadMessageReq } from '@grpc/web/chat_pb';
import { RoomType, SenderMessageType } from '@shares/context/enum';
import InfoIcon from '@assets/icons/info.svg';
import DangerIcon from '@assets/images/danger.png';
import { fileToArrayBinary, mapFilePromise, sendFileStreaming } from '@utils';
import './style.scss';

interface ChatMessagesBoxState {
  rawMessages: Message.AsObject[];
  currentMessages: { [name: string]: Message.AsObject[] };
  isBottomOfBox: boolean;
}

// type ChatMessagesBoxProp = Record<string, unknown>;
const FILE_SIZE_LIMIT = (2 * 10 ** 7) / 10 ** 6;

interface ChatMessagesBoxProp {
  toggleError?: (data: ErrorProps) => void;
}
class ChatMessagesBox extends PureComponent<ChatMessagesBoxProp, ChatMessagesBoxState> {
  static contextType = MyContext;

  context!: ContextType<typeof MyContext>;

  MessageBoxRef = createRef<HTMLDivElement>();

  constructor(props: ChatMessagesBoxProp) {
    super(props);
    this.state = {
      rawMessages: [],
      currentMessages: this.groupMessages([]),
      isBottomOfBox: false,
    };
  }

  componentDidMount() {
    const { messages, room, roomType } = this.context;
    this.fetchMessages(messages, this.handleScrollToLastMessage);

    if (!this.MessageBoxRef.current) return;
    if (!room.isBookingActive || roomType === RoomType.Admin) return;
    this.MessageBoxRef.current.addEventListener('dragover', (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (e.dataTransfer) e.dataTransfer.dropEffect = 'copy';
    });

    this.MessageBoxRef.current.addEventListener('drop', (e) => this.handleDragAndDropFile(e));
    this.MessageBoxRef.current.addEventListener(
      'scroll',
      _.throttle(() => this.handleOnScroll(), 1000),
    );
  }

  componentDidUpdate(prevProps: ChatMessagesBoxProp, prevState: ChatMessagesBoxState) {
    const { messages } = this.context;
    const { rawMessages } = this.state;

    if (messages.length !== prevState.rawMessages.length && messages.length !== rawMessages.length) {
      this.fetchMessages(messages, this.handleScrollToMessage);
    }
  }

  componentWillUnmount() {
    if (!this.MessageBoxRef.current) return;
    this.MessageBoxRef.current.removeEventListener('drop', (e) => this.handleDragAndDropFile(e));
  }

  get currentScrollPosition(): number {
    if (!this.MessageBoxRef.current) return 0;
    return this.MessageBoxRef.current.scrollHeight - this.MessageBoxRef.current.offsetHeight;
  }

  fetchMessages = (messageList: Message.AsObject[], callBack: () => any) => {
    this.setState(
      {
        rawMessages: messageList,
        currentMessages: this.groupMessages(messageList),
      },
      () => callBack(),
    );
  };

  groupMessages = (messages: Message.AsObject[]) => {
    const today = dayjs().set('h', 0).set('m', 0).set('s', 0).set('ms', 0).toISOString();
    const messageList: { [name: string]: Message.AsObject[] } = {
      [today]: [],
    };

    messages.forEach((m) => {
      const unixDateTime = parseInt(m.createdAt, 10);
      const dateTimeKey = dayjs(unixDateTime).set('h', 0).set('m', 0).set('s', 0).set('ms', 0).toISOString();
      if (messageList[dateTimeKey] === undefined) {
        messageList[dateTimeKey] = [m];
        return;
      }
      messageList[dateTimeKey].push(m);
    });

    return messageList;
  };

  scrollToBottom = () => {
    if (!this.MessageBoxRef.current) return;
    this.MessageBoxRef.current.scrollTop = this.currentScrollPosition;
  };

  setIsBottomOfTheBox = () => {
    const { rawMessages } = this.state;
    const currentLastMessage = document.getElementById(`message-${rawMessages[rawMessages.length - 1].uid}`);
    if (!currentLastMessage) return;
    const observer = new IntersectionObserver(
      (resp) => {
        this.setState({ isBottomOfBox: resp[0].intersectionRatio === 1 }, () => observer.disconnect());
      },
      { root: this.MessageBoxRef.current, threshold: [0.5] },
    );

    observer.observe(currentLastMessage);
  };

  sendReadMessage = (messageUID: string) => {
    const { grpcFunction, getRoomData } = this.context;
    if (!grpcFunction.readMessageClient) return;
    if (!getRoomData) return;

    const { bookingID, token } = getRoomData();
    const readReq = new ReadMessageReq();
    readReq.setMessageUid(messageUID);
    grpcFunction.readMessageClient(token, bookingID.toString()).send(readReq);
  };

  handleScrollToLastMessage = () => {
    const { room, messages } = this.context;
    if (messages.length === 0) return;
    if (!this.MessageBoxRef.current) return;

    this.sendReadMessage(messages[messages.length - 1].uid);
    if (room.lastMessageUid) {
      const messageUnReadRef = document.getElementById(`message-${room.lastMessageUid}`);
      if (!messageUnReadRef) return;
      let targetPosition = messageUnReadRef.offsetTop - this.MessageBoxRef.current.clientHeight / 2;
      if (messages[messages.length - 1].uid === room.lastMessageUid) {
        targetPosition = this.MessageBoxRef.current.scrollHeight;
      }
      this.MessageBoxRef.current.scrollTop = targetPosition;
    }
    this.setIsBottomOfTheBox();
  };

  handleOnScroll = () => {
    this.setIsBottomOfTheBox();
  };

  handleScrollToMessage = () => {
    const { lastMessageObj, roomType } = this.context;
    const { isBottomOfBox } = this.state;
    const currentSenderType = roomType === RoomType.Consumer ? SenderMessageType.Consumer : SenderMessageType.Dealer;
    if (!lastMessageObj) return;

    if (lastMessageObj.sender?.side !== currentSenderType) this.sendReadMessage(lastMessageObj?.uid);

    if (isBottomOfBox) {
      this.setIsBottomOfTheBox();
      this.scrollToBottom();
      return;
    }
    switch (roomType) {
      case RoomType.Consumer: {
        if (lastMessageObj.sender?.side === SenderMessageType.Consumer) this.scrollToBottom();
        break;
      }
      case RoomType.Dealer: {
        if (lastMessageObj.sender?.side === SenderMessageType.Dealer) this.scrollToBottom();
        break;
      }
      default:
    }
  };

  handleDragAndDropFile = async (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const { grpcFunction } = this.context;
    const { toggleError } = this.props;
    if (!e.dataTransfer) return;
    const { files } = e.dataTransfer;
    if (files.length > 5) {
      if (toggleError)
        toggleError({
          errorMessageTitle: 'ไฟล์หรือรูปภาพเยอะเกินไป',
          errorMessage: 'โปรดเลือกไฟล์หรือรูปภาพไม่เกิน 5 ภาพ',
        });
      return;
    }
    const fileFilter: { [key: number]: File } = {};
    Object.keys(files).forEach((key) => {
      const f = files[parseInt(key, 10)];
      const matchImageFile = f.type.match(/^image?\/(.*)/g);
      const matchPdfFile = f.type.match(/^application\/pdf$/g);
      if (f.size / 10 ** 6 > FILE_SIZE_LIMIT) {
        if (toggleError)
          toggleError({
            errorMessageTitle: 'ไฟล์ขนาดใหญ่เกินไป',
            errorMessage: `ขนาดไฟล์จำเป็นจะต้องไม่เกิน 20MB`,
          });
        return;
      }
      if (matchImageFile || matchPdfFile) {
        fileFilter[parseInt(key, 10)] = f;
        return;
      }
      if (toggleError)
        toggleError({
          errorMessageTitle: 'ไม่รองรับประเภทไฟล์นี้ ',
          errorMessage: `รองรับเฉพาะไฟล์ PNG และ JPG`,
        });
    });

    if (Object.keys(fileFilter).length <= 0) return;
    const promisesChain = mapFilePromise(fileFilter, (rawFile) => {
      fileToArrayBinary(rawFile).then((result) => {
        result.forEach((chunk, index) => {
          sendFileStreaming(chunk, result.length, index, rawFile.name, grpcFunction.fileStreamingClient).catch(
            (error) => console.log(error),
          );
        });
      });
    });
    await Promise.all(promisesChain);
  };

  getAvatar = (senderSide?: number) => {
    const { room } = this.context;
    switch (senderSide) {
      case SenderMessageType.Dealer: {
        return room.dealer?.avatar || '';
      }
      case SenderMessageType.Consumer: {
        return room.consumer?.avatar || '';
      }
      default: {
        return '';
      }
    }
  };

  getFirstMessageGroup = () => {
    const { roomType, room } = this.context;
    return (
      <>
        {roomType && roomType !== RoomType.Admin && (
          <div className="message-group__item group-remind">
            <div className="remind">
              <img src={InfoIcon} alt="info" />
              <p>
                {roomType === RoomType.Dealer
                  ? 'กรุณาตรวจสอบรายละเอียดการจองพร้อมติดต่อกลับหาลูกค้าภายใน 15 นาที'
                  : 'การจองของคุณอยู่ระหว่างการตรวจสอบ บริษัทรถเช่ากำลังติดต่อหาคุณภายใน 30 นาที'}
              </p>
            </div>
          </div>
        )}
        <div className="message-group__item">
          <BookingBox booking={room.booking} />
        </div>
      </>
    );
  };

  getSeparateLine = (messageUID: string) => {
    const { room, lastMessageObj } = this.context;
    const { rawMessages } = this.state;
    if (lastMessageObj || rawMessages[rawMessages.length - 1].uid === room.lastMessageUid) return false;
    return room.lastMessageUid === messageUID;
  };

  render() {
    const { roomType } = this.context;
    const { currentMessages } = this.state;

    return (
      <div className={`message-box message-box--${roomType}`} ref={this.MessageBoxRef}>
        <div
          style={{
            color: '#A38312',
            backgroundColor: '#FFFAE6',
            width: '100%',
            padding: '10px 20px',
            fontWeight: 'bold',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            left: 0,
          }}
        >
          ระบบแชทจะหยุดให้บริการหลังจากวันที่ 1 สิงหาคม 2024 เป็นต้นไป
        </div>

        <div
          style={{
            color: '#A38312',
            backgroundColor: '#FFFAE6',
            padding: '10px 20px',
            marginTop: '60px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div>
            <img src={DangerIcon} className="danger-icon" alt="danger" />
          </div>
          <div style={{ padding: '0 10px' }}>โปรดทำการเช่ารถผ่านช่องทางไดรฟ์ฮับ เพื่อรับการการันตีกับทางเรา</div>
        </div>

        {Object.keys(currentMessages)
          .sort((a, b) => dayjs(a).unix() - dayjs(b).unix())
          .map((d, i) => {
            const dateToString = dayjs(d).format('DD MMM YYYY');
            return (
              <div key={d} className="message-group">
                {(i > 0 && currentMessages[d].length > 0) || i === 0 ? (
                  <div className="message-group__item group-date">{dateToString}</div>
                ) : (
                  <div />
                )}
                {i === 0 && this.getFirstMessageGroup()}
                {i >= 0 &&
                  currentMessages[d]
                    .sort((a, b) => parseInt(a.createdAt, 10) - parseInt(b.createdAt, 10))
                    .map((m) => {
                      return (
                        m.sender && (
                          <MessageBox
                            customeFieldMap={m.customeFieldMap}
                            id={m.id}
                            key={m.uid}
                            avatar={this.getAvatar(m.sender?.side)}
                            text={m.text}
                            senderType={m.sender.side}
                            createdAt={m.createdAt}
                            type={m.type}
                            fileUrl={m.fileUrl}
                            fileName={m.fileName}
                            uid={m.uid}
                            showSeparateLine={this.getSeparateLine(m.uid)}
                          />
                        )
                      );
                    })}
              </div>
            );
          })}
      </div>
    );
  }
}
export default ChatMessagesBox;
