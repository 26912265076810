import { PureComponent, ContextType, createRef } from 'react';
import { MyContext } from '@shares/context';
import { ChatRenderState } from '@shares/context/enum';
import { MessageReq } from '@grpc/web/chat_pb';
import { Input, Upload, Button } from 'antd';
import { UploadChangeParam, RcFile } from 'antd/lib/upload/interface';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import CameraIcon from '@assets/icons/camera.svg';
import AlbumIcon from '@assets/icons/album.svg';
import SendIcon from '@assets/icons/send.svg';
import { fileToArrayBinary, mapFilePromise, sendFileStreaming } from '@utils';
import './style.scss';

const INPUT_DEFAULT_HEIGHT = 48;
const FILE_SIZE_LIMIT = (2 * 10 ** 7) / 10 ** 6;
interface ChatFooterState {
  inputMessage: string;
  inputHeight: number;
  defaultInputHeight: number;
  inputElement: HTMLElement | null;
}
interface ChatFooterProps {
  isMobile: boolean;
  toggleError?: (data: ErrorProps) => void;
}

class ChatFooter extends PureComponent<ChatFooterProps, ChatFooterState> {
  static contextType = MyContext;

  context!: ContextType<typeof MyContext>;

  InputMessageRef = createRef<TextAreaRef>();

  InputBoxRef = createRef<HTMLDivElement>();

  FooterBoxRef = createRef<HTMLDivElement>();

  constructor(props: ChatFooterProps) {
    super(props);
    this.state = {
      inputMessage: '',
      inputHeight: 0,
      defaultInputHeight: 0,
      inputElement: null,
    };
  }

  componentDidMount() {
    const messageInputRef = document.getElementById('message-input-ref');
    if (!messageInputRef) return;

    this.setState({ inputElement: messageInputRef });
    messageInputRef.addEventListener('keyup', this.handleMessageInputResize, false);
  }

  componentDidUpdate(preveProps: ChatFooterProps, prevState: ChatFooterState) {
    const { inputElement } = this.state;

    if (prevState.inputHeight === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ inputHeight: inputElement?.scrollHeight || 0 });
    }
    if (prevState.defaultInputHeight === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ defaultInputHeight: inputElement?.scrollHeight || 0 });
    }
  }

  componentWillUnmount() {
    const { inputElement } = this.state;
    if (!inputElement) return;
    inputElement.removeEventListener('keyup', this.handleMessageInputResize, false);
  }

  handleMessageInputResize = () => {
    const { inputElement } = this.state;
    this.setState({ inputHeight: inputElement?.scrollHeight || 0 }, () => {
      const chatMessageRef = document.getElementById('chat-message-ref');
      if (!chatMessageRef) return;
      chatMessageRef.style.paddingBottom = `${this.FooterBoxRef.current?.clientHeight}px`;
    });
  };

  handleInput = (
    e: React.KeyboardEvent<HTMLTextAreaElement> | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
  ) => {
    if (e) e.preventDefault();
    if (!this.InputMessageRef.current) return;
    const { inputMessage } = this.state;
    const { grpcFunction } = this.context;
    const { isMobile } = this.props;
    const chatMessageRef = document.getElementById('chat-message-ref');

    this.InputMessageRef.current.focus();
    if (inputMessage === '') return;
    if (isMobile) {
      if (!chatMessageRef) return;
      chatMessageRef.style.paddingBottom = `${INPUT_DEFAULT_HEIGHT}px`;
    }

    const req = new MessageReq();
    req.setText(inputMessage);
    if (grpcFunction.messageClient) grpcFunction.messageClient.send(req);
    this.setState({ inputMessage: '' });
  };

  handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ inputMessage: e.currentTarget.value });
  };

  onChangeUpload = (info: UploadChangeParam) => {
    if (info.file.status === 'uploading') {
      // console.log('image uploading');
    }
    if (info.file.status === 'error') {
      const { toggleError } = this.props;
      const errorMessage: ErrorProps = info.file.error
        ? { errorMessageTitle: 'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ', errorMessage: info.file.error.message }
        : {};
      if (toggleError) toggleError(errorMessage);
    }
    if (info.file.status === 'done') {
      // console.log('image upload done');
    }
  };

  uploadImages = ({ onSuccess, onError, file }: any) => {
    const { grpcFunction } = this.context;
    const { toggleError } = this.props;

    if (file.size / 10 ** 6 > FILE_SIZE_LIMIT) {
      if (toggleError)
        toggleError({ errorMessageTitle: 'ไฟล์ขนาดใหญ่เกินไป', errorMessage: `ขนาดไฟล์จำเป็นจะต้องไม่เกิน 20MB` });
      onError(new Error(`ขนาดไฟล์จำเป็นจะต้องไม่เกิน 20MB`), file);
      return;
    }

    fileToArrayBinary(file)
      .then((binaryRaw) => {
        binaryRaw.forEach((chunk, index) => {
          sendFileStreaming(chunk, binaryRaw.length, index, file.name, grpcFunction.fileStreamingClient)
            .then(() => onSuccess({}, file))
            .catch((e) => {
              console.log(e);
              onError(e, 'Upload Fail');
            });
        });
      })
      .catch((e) => {
        console.log(e);
        onError(e, 'Upload Fail', file);
      });
  };

  validateFileUpload = (files: RcFile[]): boolean => {
    if (files.length > 5) {
      const { toggleError } = this.props;
      if (toggleError)
        toggleError({
          errorMessageTitle: 'ไฟล์หรือรูปภาพเยอะเกินไป',
          errorMessage: 'โปรดเลือกไฟล์หรือรูปภาพไม่เกิน 5 ภาพ',
        });
      return false;
    }
    return true;
  };

  handleOnCapture = async (files: FileList | null) => {
    const { grpcFunction } = this.context;
    if (!files) return;
    const promisesChain = mapFilePromise(files, (rawFile) => {
      fileToArrayBinary(rawFile).then((result) => {
        result.forEach((chunk, index) => {
          sendFileStreaming(chunk, result.length, index, rawFile.name, grpcFunction.fileStreamingClient).catch((e) =>
            console.log(e),
          );
        });
      });
    });

    await Promise.all(promisesChain);
  };

  render() {
    const { inputMessage, inputHeight, defaultInputHeight } = this.state;

    return (
      <div className={`footer-box ${inputHeight > defaultInputHeight ? 'resize' : ''}`} ref={this.FooterBoxRef}>
        <div className="footer-box__item footer-box__item--gallery">
          <Upload
            name="file"
            accept="image/*,application/pdf"
            showUploadList={false}
            onChange={this.onChangeUpload}
            customRequest={this.uploadImages}
            beforeUpload={(file, fileList) => this.validateFileUpload(fileList)}
            multiple
          >
            <Button type="text" icon={<img src={AlbumIcon} alt="album" />} className="btn btn--album" />
          </Upload>
        </div>
        <div className="footer-box__item footer-box__item--camera">
          <label htmlFor="camera-input-ref" className="btn btn--camera">
            <Input
              id="camera-input-ref"
              type="file"
              accept="image/*"
              capture
              bordered={false}
              className="camera-input"
              onChange={(e) => this.handleOnCapture(e.target.files)}
            />
            <img src={CameraIcon} alt="camera" />
          </label>
        </div>
        <div className={`footer-box__item ${inputHeight > defaultInputHeight ? 'resize' : ''}`} ref={this.InputBoxRef}>
          <Input.TextArea
            id="message-input-ref"
            placeholder="พิมพ์ข้อความ..."
            className="message-input"
            onPressEnter={(e) => {
              const { chatRenderState } = this.context;
              if (chatRenderState === ChatRenderState.Mobile) {
                this.handleMessageInputResize();
                return;
              }
              this.handleInput(e);
            }}
            onChange={(e) => this.handleOnChangeInput(e)}
            value={inputMessage}
            autoSize
            ref={this.InputMessageRef}
          />
          <button onClick={(e) => this.handleInput(e)} type="button" className="btn btn--send">
            <img src={SendIcon} alt="send" />
          </button>
        </div>
      </div>
    );
  }
}

export default ChatFooter;
