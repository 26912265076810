import {
  JoinRoomRes,
  JoinRoomReq,
  MessageReq,
  Message,
  ReadMessageReq,
  UserStatusRes,
  None,
  FileReq,
} from '@grpc/web/chat_pb';
import { Chat } from '@grpc/web/chat_pb_service';
import { GRPC_END_POINT } from '@config';
import { grpc } from '@improbable-eng/grpc-web';

import type {
  ChatJoinRoom,
  ChatMessageStreaming,
  ChatGetUserStatus,
  ChatReadMessage,
  ChatFileStreaming,
} from '@grpc/web/chat_pb_service';

export const joinRoom = (token: string, bookingID: string) => {
  const client = grpc.client<JoinRoomReq, JoinRoomRes, ChatJoinRoom>(Chat.JoinRoom, {
    host: GRPC_END_POINT,
    transport: grpc.WebsocketTransport(),
    debug: true,
  });
  client.start(
    new grpc.Metadata({
      'booking-id': bookingID,
      authorization: token,
    }),
  );
  return client;
};
export const fileStreaming = (token: string, bookingID: string) => {
  const client = grpc.client<FileReq, Message, ChatFileStreaming>(Chat.FileStreaming, {
    host: GRPC_END_POINT,
    transport: grpc.WebsocketTransport(),
    debug: true,
  });
  client.start(
    new grpc.Metadata({
      'booking-id': bookingID,
      authorization: token,
    }),
  );
  return client;
};

export const messageStreaming = (token: string, bookingID: string) => {
  const client = grpc.client<MessageReq, Message, ChatMessageStreaming>(Chat.MessageStreaming, {
    host: GRPC_END_POINT,
    transport: grpc.WebsocketTransport(),
    debug: true,
  });
  client.start(
    new grpc.Metadata({
      'booking-id': bookingID,
      authorization: token,
    }),
  );
  return client;
};

export const readMessage = (token: string, bookingID: string) => {
  const client = grpc.client<ReadMessageReq, None, ChatReadMessage>(Chat.ReadMessage, {
    host: GRPC_END_POINT,
    transport: grpc.WebsocketTransport(),
    debug: true,
  });
  client.start(
    new grpc.Metadata({
      'booking-id': bookingID,
      authorization: token,
    }),
  );
  return client;
};

export const userStatus = (token: string, bookingID: string) => {
  const client = grpc.client<None, UserStatusRes, ChatGetUserStatus>(Chat.GetUserStatus, {
    host: GRPC_END_POINT,
    transport: grpc.WebsocketTransport(),
    debug: true,
  });
  client.start(
    new grpc.Metadata({
      'booking-id': bookingID,
      authorization: token,
    }),
  );
  return client;
};

export default joinRoom;
