import { FunctionComponent, PureComponent } from 'react';
import dayjs, { extend } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { MyContext } from '@shares/context';
import { PageHeader } from 'antd';
import { UserOutlined, LeftOutlined } from '@ant-design/icons';
import { SEARCH_END_POINT } from '@config';
import './style.scss';

interface ChatHeaderTitleProp {
  title?: string;
  subTilte: string;
  useStatus: boolean;
}
const ChatHeaderTitle: FunctionComponent<ChatHeaderTitleProp> = ({ title, subTilte, useStatus }) => {
  return (
    <div className="header-title">
      <p>{title}</p>
      {!useStatus && <p className="sub-title">{subTilte}</p>}
    </div>
  );
};
interface ChatHeaderState {
  userStatus: boolean;
}

type ChatHeaderProps = Record<string, unknown>;

class ChatHeader extends PureComponent<ChatHeaderProps, ChatHeaderState> {
  static contextType = MyContext;

  context!: React.ContextType<typeof MyContext>;

  constructor(props: ChatHeaderProps) {
    super(props);
    this.state = {
      userStatus: false,
    };
  }

  componentDidMount() {
    const { opponentIsActive } = this.context;
    this.setState({ userStatus: opponentIsActive });
  }

  componentDidUpdate(prevProps: ChatHeaderProps, prevState: ChatHeaderState) {
    const { opponentIsActive } = this.context;
    if (prevState.userStatus !== opponentIsActive) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ userStatus: opponentIsActive });
    }
  }

  lastActive = () => {
    const { lastOpponentActiveAt } = this.context;
    const unixDateTime = parseInt(lastOpponentActiveAt, 10);
    extend(relativeTime);
    return dayjs(unixDateTime).fromNow();
  };

  toggleBackBtn = () => {
    const { room } = this.context;
    const lastName = room.consumer?.name.split(' ')[1];
    window.open(`${SEARCH_END_POINT}/booking/${room.booking?.id}?source=local&last_name=${lastName}`, '_parent');
  };

  render() {
    const { userStatus } = this.state;
    const { room, lastOpponentActiveAt } = this.context;
    const lastActiveString = lastOpponentActiveAt === '' ? '' : `ใช้งานล่าสุด ${this.lastActive()}`;
    return (
      <PageHeader
        className="header-box"
        onBack={() => null}
        title={<ChatHeaderTitle title={room.dealer?.name} subTilte={lastActiveString} useStatus={userStatus} />}
        backIcon={<LeftOutlined className="back-btn" onClick={this.toggleBackBtn} />}
        tags={<div className={`status ${userStatus ? 'active' : 'offline'}`} />}
        avatar={{
          src: room.dealer?.avatar,
          className: 'header-avatar',
          icon: <UserOutlined />,
        }}
      />
    );
  }
}

export default ChatHeader;
